window.dataLayer = window.dataLayer || [];
// Avoid history change tags and element clicks
dataLayer.push({
  'gtm.blocklist': ['hl', 'cl']
});

function gtag(){
  dataLayer.push(arguments[0]);
}

gtag({'js': new Date()});
// gtag({'config': 'GTM-PTD9CB2', 'page_title': 'Aula planeta'});